import HeroSection from "../components/hero/HeroSection";
import Head from "next/head";

export default function Home() {
  return (
    <>
      <Head>
        <title>Barbara Osowski | REALTOR</title>

        <meta
          name="description"
          content="Barbara Osowski, realtor in Colorado Springs, CO."
        />
      </Head>
      <HeroSection />
    </>
  );
}
