import React from "react";
import styled from "styled-components";
import { useRouter } from "next/router";

import HeadingCta from "./HeadingCta";

const HeroContainer = styled.section`
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  :hover {
    cursor: pointer;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const VideoContent = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const HeroSection = () => {
  const router = useRouter();

  return (
    <HeroContainer onClick={() => router.push("/content")}>
      <VideoContainer>
        <VideoContent
          autoPlay
          preload="true"
          playsInline
          muted
          loop
          poster="/photos/poster.png"
        >
          <source src="/video/hero.mp4" />
        </VideoContent>
      </VideoContainer>
      <HeadingCta />
    </HeroContainer>
  );
};

export default HeroSection;
